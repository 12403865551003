import React from "react";
import "../components/style.scss";
import Helmet from "../components/layout/helmet";
import BatteriesHeader from "../components/headers/batteries";
import BatteryMidSection from "../components/contents/battery";
import Footer from "../components/layout/footer";

const BatteryPage = () => (
  <div className="homepage quotes">
    <Helmet />
    <BatteriesHeader />
    <BatteryMidSection />
    <Footer />
  </div>
);

export default BatteryPage;
